import moment from 'moment'

import type { RootState } from 'store'

export type CommonParams = {
  accessToken: string | null
  idToken: string | null
  tenantId: number
  userId: string
  updatedBy: string
}

export const commonParams = (getState: () => RootState): CommonParams => {
  const {
    accessToken,
    idToken,
    user: {
      userId,
      userHasTenants: {
        0: { nickname, id },
      },
    },
    team: { workerName },
  } = getState().session
  const tenantId = id || 0
  return {
    accessToken,
    idToken,
    tenantId,
    userId,
    updatedBy: workerName || (nickname ?? ''),
  }
}

const SPLIT_PERIOD = 6
export const getSplitPeriods = (from: string, to: string): [string, string][] => {
  const totalDays = moment(to).diff(from, 'days') + 1
  // それぞれのリクエストで取得する日付
  return [...Array(SPLIT_PERIOD)].reduce((acc: [string, string][], _, index) => {
    const diffFrom = Math.ceil((totalDays * index) / SPLIT_PERIOD)
    const diffTo = Math.ceil((totalDays * (index + 1)) / SPLIT_PERIOD) - 1

    if (totalDays < SPLIT_PERIOD && diffTo < diffFrom) {
      return acc
    }
    return [
      ...acc,
      [moment(from).add(diffFrom, 'days').format('YYYY-MM-DD'), moment(from).add(diffTo, 'days').format('YYYY-MM-DD')],
    ]
  }, [])
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const downloadByURL = async (url: string, filename: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  exportData(blob, filename)
}

export const exportData = (blob: Blob, filename: string) => {
  const objUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = filename
  link.href = objUrl
  link.click()
  window.URL.revokeObjectURL(objUrl)
}
