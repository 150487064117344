import { useRef } from 'react'
import Popup from 'reactjs-popup'

import { TableCheckbox, ShiftBar } from 'components/common'
import type { ShiftBarItemType } from 'components/common/types'

import { PerformanceIndicesPopover } from './PerformanceIndicesPopover'

import styles from './AssignToWorkTableWorkerRow.module.scss'

import type { EditGroupsWorkerType } from '../types'
import type { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  worker: EditGroupsWorkerType
  checkBoxVisible: boolean
  shiftBarItems: ShiftBarItemType[]
  businessStartTime: string
  shiftBarWidth: number
  shiftBarActiveRange: [number, number][] | undefined
  shiftBarClassName: string
  shiftBarDisabled: boolean
  headerClassName: string
  onShiftBarAdd: (startPos: number, endPos: number) => void
  onShiftBarChange: (index: number, x: number, width: number) => void
  onCheckBoxClick: (checked: boolean) => void
  checkboxChecked: boolean
}

const POPUP_CONTENT_MIN_WIDTH = '240px'
const POPUP_CONTENT_MAX_WIDTH = '440px'
const POPUP_ARROW_POSITION_Y = '150px'

export const AssignToWorkTableWorkerRow = ({
  worker,
  checkBoxVisible,
  businessStartTime,
  shiftBarWidth,
  shiftBarActiveRange,
  shiftBarItems,
  shiftBarClassName,
  shiftBarDisabled,
  headerClassName,
  onShiftBarAdd,
  onShiftBarChange,
  onCheckBoxClick,
  checkboxChecked,
}: Props) => {
  const ref = useRef<PopupActions>(null)

  return (
    <tr key={`worker-${worker.workerId}`} className={styles.tableRow}>
      <td className={headerClassName}>
        <div className="d-flex align-items-center">
          {checkBoxVisible && (
            <TableCheckbox
              id={`assign-to-work-table-check-worker-${worker.workerId}`}
              checked={checkboxChecked}
              onClick={onCheckBoxClick}
            />
          )}

          <Popup
            ref={ref}
            trigger={<div className="text-truncate me-auto ms-3">{worker.name}</div>}
            position={['left center', 'left top', 'left bottom']}
            contentStyle={{
              minWidth: POPUP_CONTENT_MIN_WIDTH,
              maxWidth: POPUP_CONTENT_MAX_WIDTH,
              height: POPUP_ARROW_POSITION_Y,
            }}
            arrow
            repositionOnResize
            arrowStyle={{
              transform: 'rotate(45deg) translateX(5px)',
              backgroundColor: 'white',
              width: '10px',
              height: '10px',
              border: 'var(--bs-border-width) solid var(--bs-border-color-translucent)',
            }}
          >
            <PerformanceIndicesPopover workerId={worker.workerId} />
          </Popup>
          <i role="button" className="icf-info font-large" onClick={() => ref.current?.open()} />
        </div>
      </td>

      <td className={shiftBarClassName}>
        <ShiftBar
          items={shiftBarItems}
          businessStartTime={businessStartTime}
          shiftBarWidth={shiftBarWidth}
          activeRange={shiftBarActiveRange}
          onAdd={onShiftBarAdd}
          onChange={onShiftBarChange}
          disabled={shiftBarDisabled}
        />
      </td>
    </tr>
  )
}
