import { encode } from 'iconv-lite'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Input, Label } from 'reactstrap'

import { WorkerTypes, type WorkerType } from 'api/workers'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { exportData } from 'slices/utils'
import { selectWorkersStatus, importWorkers, exportWorkers } from 'slices/workersSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import type { CharacterCodeType } from 'components/common/types'
import { CharacterCodeTypes, ColumnSizes, WorkerTypesLabel } from 'components/common/utils'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

type CharacterCodeItemType = { key: CharacterCodeType; value: CharacterCodeType }

const MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES = [
  '名前',
  'メンバーID',
  '職掌',
  'スキル設定',
  'メンバー削除',
]
const MAX_MEMBER_IMPORT_COLUMNS = 2000

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CharacterCodeTypes.shiftJis, value: CharacterCodeTypes.shiftJis },
  { key: CharacterCodeTypes.utf8, value: CharacterCodeTypes.utf8 },
]

const WorkersImportDialog = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string>()
  const [file, setFile] = useState<File | null>(null)
  const [characterCode, setCharacterCode] = useState(characterCodeItem[0])
  const [selectedWorkerType, setSelectedWorkerType] = useState<WorkerType>(WorkerTypes.RegularMember)
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const dispatch = useDispatch()

  const disabled = useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const isRegularMemberSelected = useMemo(() => selectedWorkerType === WorkerTypes.RegularMember, [selectedWorkerType])

  const handleSaveClick = () => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    if (characterCode.value === CharacterCodeTypes.shiftJis) {
      reader.readAsText(file, 'SJIS')
    } else {
      reader.readAsText(file)
    }
    reader.addEventListener('load', () => {
      const content = reader.result
      if (content) {
        dispatch(importWorkers(file.name, selectedWorkerType, content.toString()))
        setSubmitted(true)
      } else {
        setModalErrorMessage('ファイルが空です。')
      }
    })
    reader.addEventListener('error', () => setModalErrorMessage('ファイルの読み込みに失敗しました。'))
    setSelectedWorkerType(WorkerTypes.RegularMember)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleDownloadFormat = () => {
    if (isRegularMemberSelected) {
      const data = {
        workerType: selectedWorkerType,
        replaceAverageProductivity: false,
        includeUnaffiliatedWorker: false,
        targetWorkspaceIds: [],
      }
      dispatch(exportWorkers(data, 'レギュラーメンバー登録シート.csv'))
      return
    }

    const csvAllScheduleTypesCsvHeader = allScheduleTypes.map(s => `人時生産性_${s?.name}_${s?.workspaceName}[${s.id}]`)

    MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.splice(
      MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.length - 1,
      0,
      ...csvAllScheduleTypesCsvHeader
    )
    const formatHeader = [MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES]
      .map(row => row.join(','))
      .join('\n')
    const csvData = encode(formatHeader, characterCode.key)
    const blob = new Blob([csvData], { type: 'text/csv' })
    exportData(blob, 'スポットメンバー登録シート.csv')
  }

  const WorkerTypeText = useMemo(
    () => (isRegularMemberSelected ? 'レギュラーメンバー' : 'スポットメンバー'),
    [isRegularMemberSelected]
  )

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーインポート"
      approveLabel="CSVインポート"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="member-import-submit"
    >
      <div className="pb-4">
        <div>
          <div className="my-2 fw-bold">メンバー属性の選択</div>
          <div className="mb-2">メンバーエクスポートするメンバー属性を選択してください</div>
          <FormGroup check className="d-flex flex-column row-gap-2">
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={isRegularMemberSelected}
                onChange={() => setSelectedWorkerType(WorkerTypes.RegularMember)}
              />
              {WorkerTypesLabel.find(w => w.key === WorkerTypes.RegularMember)?.value}
            </Label>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={!isRegularMemberSelected}
                onChange={() => setSelectedWorkerType(WorkerTypes.SpotMember)}
              />
              {WorkerTypesLabel.find(w => w.key === WorkerTypes.SpotMember)?.value}
            </Label>
          </FormGroup>
        </div>
        <div className="my-3">
          {`下記${WorkerTypeText}登録用シートをダウンロードして、同じフォーマットでCSVアップロードをしてください。`}
        </div>
        <SelectBoxFormat
          label="文字コード選択"
          value={characterCode.key}
          size={ColumnSizes.middle}
          items={characterCodeItem}
          onChange={item => setCharacterCode(item as CharacterCodeItemType)}
          className="w-60 mb-3"
        />
        <ul className="mb-3">
          <li>
            メンバーインポートについて詳しくは
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href="https://help.smileboard.jp/member-import"
            >
              こちら
            </a>
            をご確認ください。
          </li>
          <li>データを更新する場合は現在のメンバーマスタをエクスポートし、編集してインポートしてください。</li>
          <li>
            {`一度にインポートできるのはヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}
            行までです。適宜分割してインポートしてください。`}
            <span className="text-danger">
              {`アップロードされたCSVファイルがヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}行より多い場合は処理は実施されません。`}
            </span>
          </li>
        </ul>
        <Button color="link" className="mb-3 ps-0 shadow-none text-decoration-none" onClick={handleDownloadFormat}>
          {`${WorkerTypeText}登録用シートをダウンロード`}
        </Button>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default WorkersImportDialog
