import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import type { TenantApplicationSettingType, TenantData } from 'api/tenants'
import { Role } from 'api/users'
import type { RoleType } from 'api/users'
import type { WorkerType } from 'api/workers'
import { WorkerTypes } from 'api/workers'

import type { ConnectionType, EditSchedule, TimeReducerType } from './types'
export {
  createLineChartOptions,
  createBarChartOptions,
  createStackedChartOptions,
  getMaxYAxisValue,
} from 'components/common/Chart/Chart'
export { COLORS, ColorTypes } from 'components/common/ColorPicker/ColorPicker'
export { PlaceholderTypes } from 'components/common/NotSelectedPlaceholder/NotSelectedPlaceholder'
export * as Rules from 'components/common/FormFormat/ValidationRules'

export type FitDuringShiftTimeData = {
  workerId: number
  startAt: string
  duration: number
}

export const UNSELECTED_SCHEDULE_TYPE_ID = 0
export const SHIFT_SCHEDULE_TYPE_ID = -1
export const SUPPORT_SCHEDULE_TYPE_ID = -3
export const CELL_WIDTH = 20

export const prefectureItems = [
  { value: '北海道' },
  { value: '青森県' },
  { value: '岩手県' },
  { value: '宮城県' },
  { value: '秋田県' },
  { value: '山形県' },
  { value: '福島県' },
  { value: '茨城県' },
  { value: '栃木県' },
  { value: '群馬県' },
  { value: '埼玉県' },
  { value: '千葉県' },
  { value: '東京都' },
  { value: '神奈川県' },
  { value: '新潟県' },
  { value: '富山県' },
  { value: '石川県' },
  { value: '福井県' },
  { value: '山梨県' },
  { value: '長野県' },
  { value: '岐阜県' },
  { value: '静岡県' },
  { value: '愛知県' },
  { value: '三重県' },
  { value: '滋賀県' },
  { value: '京都府' },
  { value: '大阪府' },
  { value: '兵庫県' },
  { value: '奈良県' },
  { value: '和歌山県' },
  { value: '鳥取県' },
  { value: '島根県' },
  { value: '岡山県' },
  { value: '広島県' },
  { value: '山口県' },
  { value: '徳島県' },
  { value: '香川県' },
  { value: '愛媛県' },
  { value: '高知県' },
  { value: '福岡県' },
  { value: '佐賀県' },
  { value: '長崎県' },
  { value: '熊本県' },
  { value: '大分県' },
  { value: '宮崎県' },
  { value: '鹿児島県' },
  { value: '沖縄県' },
]

export const accountTypeName = (role: RoleType | undefined) => {
  switch (role) {
    case Role.TenantAdmin:
      return 'サービス管理者'
    case Role.Admin:
      return 'オーナー'
    case Role.ProcessAdmin:
      return 'オペレーター'
    default:
      return '不明'
  }
}

export const getShiftBarWidthByDuration = (duration: number) => {
  return duration / 900
}

export const getUpdateWorkerSchedules = (
  startAt: string,
  duration: number,
  scheduleTypeId: number,
  supportWorkspaceId: number | null,
  supportWorkspaceName: string | null,
  schedules: EditSchedule[]
) => {
  let addSchedule: EditSchedule | undefined = {
    scheduleId: null,
    startAt,
    duration,
    scheduleTypeId,
    supportWorkspaceId,
    supportWorkspaceName,
  }
  const shifts = schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
  const updatedSchedules = schedules
    .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
    .reduce((acc: EditSchedule[], cur) => {
      if (!addSchedule) {
        cur.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID && acc.push(cur)
        return acc
      }
      const addStart = moment(addSchedule.startAt)
      const addEnd = moment(addSchedule.startAt).add(addSchedule.duration, 'seconds')
      const curStart = moment(cur.startAt)
      const curEnd = moment(cur.startAt).add(cur.duration, 'seconds')

      if (addStart.isBetween(curStart, curEnd, 'minute', '()') && addEnd.isBetween(curStart, curEnd, 'minute', '()')) {
        if (cur.scheduleTypeId !== scheduleTypeId || supportWorkspaceId !== cur.supportWorkspaceId) {
          acc.push({ ...cur, duration: cur.duration - (curEnd.unix() - addStart.unix()) })
          acc.push({
            scheduleId: null,
            startAt: addEnd.format(),
            duration: cur.duration - (addEnd.unix() - curStart.unix()),
            scheduleTypeId: cur.scheduleTypeId,
            supportWorkspaceId: cur.supportWorkspaceId,
            supportWorkspaceName: cur.supportWorkspaceName,
          })
        } else {
          acc.push(cur)
          addSchedule = undefined
        }
      } else if (addStart.isBetween(curStart, curEnd, 'minute', '(]')) {
        const diff = curEnd.unix() - addStart.unix()
        if (cur.scheduleTypeId === scheduleTypeId && supportWorkspaceId === cur.supportWorkspaceId) {
          addSchedule = { ...cur, duration: cur.duration + addSchedule.duration - diff }
        } else {
          acc.push({ ...cur, duration: cur.duration - diff })
        }
      } else if (addEnd.isBetween(curStart, curEnd, 'minute', '[)')) {
        const diff = addEnd.unix() - curStart.unix()
        if (scheduleTypeId === cur.scheduleTypeId && supportWorkspaceId === cur.supportWorkspaceId) {
          addSchedule = { ...cur, startAt: addSchedule.startAt, duration: cur.duration + addSchedule.duration - diff }
        } else {
          acc.push({ ...cur, startAt: addEnd.format(), duration: cur.duration - diff })
        }
      } else if (
        curStart.isBetween(addStart, addEnd, 'minute', '[]') &&
        curEnd.isBetween(addStart, addEnd, 'minute', '[]')
      ) {
        return acc
      } else {
        acc.push(cur)
      }
      return acc
    }, [])

  return _.concat(updatedSchedules, shifts, _.compact([addSchedule]))
}

export const toFitDuringShiftTime = (
  inputStartAt: string,
  inputDuration: number,
  shifts: EditSchedule[] | FitDuringShiftTimeData[],
  isEndTime: boolean
) => {
  const inputEndAt = moment(inputStartAt).add(inputDuration, 'seconds').format()
  const isBetween = shifts.some(shift => {
    const shiftEndAt = moment(shift.startAt).add(shift.duration, 'seconds').format()
    return (
      moment(inputStartAt).isBetween(shift.startAt, shiftEndAt, 'minutes', '[]') &&
      moment(inputEndAt).isBetween(shift.startAt, shiftEndAt, 'minutes', '[]')
    )
  })

  if (!isEndTime && (isBetween || shifts.length === 0)) {
    return { startAt: inputStartAt, duration: inputDuration }
  }

  const baseShift =
    shifts.find(shift => {
      const shiftEndAt = moment(shift.startAt).add(shift.duration, 'seconds').format()
      return (
        (moment(shift.startAt).isBetween(inputStartAt, inputEndAt, 'minutes', '[]') &&
          moment(shiftEndAt).isBetween(inputStartAt, inputEndAt, 'minutes', '[]')) ||
        moment(inputStartAt).isBetween(shift.startAt, shiftEndAt, 'minutes') ||
        moment(inputEndAt).isBetween(shift.startAt, shiftEndAt, 'minutes')
      )
    }) || shifts[0]
  const baseEndAt = moment(baseShift.startAt).add(baseShift.duration, 'seconds').format()
  const startAt = moment(inputStartAt).isAfter(baseShift.startAt) || isEndTime ? inputStartAt : baseShift.startAt
  const end = moment(inputEndAt).isAfter(baseEndAt) || isEndTime ? baseEndAt : inputEndAt
  const duration = moment(end).unix() - moment(startAt).unix()

  return { startAt, duration }
}

export const MAGIQANNEAL_APPLICATION_ID = 1

const initialMagiQannealData: TenantApplicationSettingType = {
  applicationId: MAGIQANNEAL_APPLICATION_ID,
  applicationName: 'magiQanneal連携',
  options: { apiKey: '', tenant: '', relatedWorkspaceData: [] },
}

export const getTenantApplications = (tenant?: TenantData) => {
  if (!tenant) {
    return []
  }
  if (_.isEmpty(tenant.optionApplications)) {
    return [initialMagiQannealData]
  }
  const magiQanneal = tenant.optionApplications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
  if (magiQanneal && magiQanneal.applicationName !== initialMagiQannealData.applicationName) {
    return tenant.optionApplications.map(app =>
      app.applicationId === MAGIQANNEAL_APPLICATION_ID
        ? { ...magiQanneal, applicationName: initialMagiQannealData.applicationName }
        : app
    )
  }
  return tenant.optionApplications
}

export const timeOverlapped = (
  schedule1StartAt: string,
  schedule1Duration: number,
  schedule2StartAt: string,
  schedule2Duration: number
) => {
  const time1 = moment(schedule1StartAt)
  const time2 = moment(schedule2StartAt)
  return (
    time1.clone().add(schedule1Duration, 'seconds').isAfter(time2) &&
    time1.isBefore(time2.clone().add(schedule2Duration, 'seconds'))
  )
}
// 作業の時間が重なっていないかチェックする
export const hasOverlappedSchedule = (schedules: EditSchedule[], isShift: boolean) => {
  return schedules.some((controlSchedule, index) => {
    return schedules.slice(index + 1).some(targetSchedule => {
      if (
        !isShift &&
        (controlSchedule.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ||
          targetSchedule.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
      ) {
        return false
      }
      return timeOverlapped(
        controlSchedule.startAt,
        controlSchedule.duration,
        targetSchedule.startAt,
        targetSchedule.duration
      )
    })
  })
}

const calcRate = (planCount: number | null, recordCount: number | null): number | null => {
  if (planCount === null || planCount === 0 || recordCount === null) {
    return null
  }
  return Math.floor((100 * recordCount) / planCount)
}

export const append = (value1: number | null, value2: number | null): number | null => {
  if (value1 === null && value2 === null) {
    return null
  }
  return (value1 || 0) + (value2 || 0)
}

export const timeDataReducer = (acc: { [key: string]: TimeReducerType }, cur: TimeReducerType) => {
  const before = acc[cur.time]
  if (before) {
    const planCount = append(before.planCount, cur.planCount)
    const recordCount = append(before.recordCount, cur.recordCount)
    return {
      ...acc,
      [before.time]: {
        time: before.time,
        planCount,
        recordCount,
        rate: calcRate(planCount, recordCount),
      },
    }
  }
  return {
    ...acc,
    [cur.time]: {
      ...cur,
      rate: calcRate(cur.planCount, cur.recordCount),
    },
  }
}

export const getConnectionTypeLabel = (connectionType: ConnectionType) => {
  switch (connectionType) {
    case connectionTypes.None:
      return '実績なし'
    case connectionTypes.Auto:
      return '自動アップロード'
    case connectionTypes.Manual:
      return '手動アップロード'
    default:
      return '実績なし'
  }
}

// 0以下のランダムな数値を取得する
export const getRandomNumber = () => window.crypto.getRandomValues(new Uint32Array(1))[0] / 10000000000

export const getUUID = () => uuidv4()

export const connectionTypes = {
  None: 0,
  Auto: 1,
  Manual: 2,
} as const

export const CharacterCodeTypes = {
  shiftJis: 'SHIFT_JIS',
  utf8: 'UTF-8',
} as const

export const ColumnSizes = {
  x_short: 3,
  short: 4,
  middle: 6,
  large: 8,
  x_large: 9,
} as const

export const isSupportedWorkerGroup = (supportedWorkspaceId: number | null, supportedWorkspaceName: string | null) => {
  return !!(supportedWorkspaceId && supportedWorkspaceName)
}

type WorkerFilterProps = {
  name: string
  wmsMemberId: string | null
  workerType: WorkerType
}

export const isFilteredWorker = (filterWord: string, worker: WorkerFilterProps) =>
  filterWord === '' ||
  worker.name.includes(filterWord) ||
  (worker.workerType === WorkerTypes.RegularMember && worker.wmsMemberId?.includes(filterWord))

export const WorkerTypesLabel = [
  {
    key: WorkerTypes.RegularMember,
    value: 'レギュラーメンバー',
    description: '',
  },
  {
    key: WorkerTypes.SpotMember,
    value: 'スポットメンバー',
    description:
      'スポットメンバーとして登録した場合、所属ワークスペース、所属グループ、識別子はスポットメンバー管理から営業日毎に登録してください。',
  },
]

export const WEEKDAY_SUNDAY = 0
