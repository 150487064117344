import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { showSuccess } from 'slices/notificationSlice'
import { csvImportTargets, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import type { CharacterCodeType } from 'components/common/types'
import { CharacterCodeTypes, ColumnSizes } from 'components/common/utils'

import { useTargetsImport, FORMAT_FILE_NAME } from 'hooks/useTargetsImport'

type Props = {
  workDate?: string
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

type CharacterCodeItemType = { key: CharacterCodeType; value: CharacterCodeType }

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CharacterCodeTypes.shiftJis, value: CharacterCodeTypes.shiftJis },
  { key: CharacterCodeTypes.utf8, value: CharacterCodeTypes.utf8 },
]

const ImportTargetsDialog: React.FC<Props> = ({ isOpen, onSuccess, onCancel, workDate }) => {
  const { workspaceId } = useParams<'workspaceId'>()
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [file, setFile] = React.useState<File | null>(null)
  const [characterCode, setCharacterCode] = React.useState(characterCodeItem[0])

  const dispatch = useDispatch()

  const { isRequesting, errorMessage } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { handleDataFormat, formatDate } = useTargetsImport()

  const disabled = React.useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const handleApprove = React.useCallback(() => {
    if (!file || !workspaceId) {
      setModalErrorMessage('ファイルを選択してください')
      return
    }

    const encoding = characterCode.value === CharacterCodeTypes.shiftJis ? 'SJIS' : 'UTF8'
    const formData = new FormData()
    formData.append('fileData', file)
    formData.append('encoding', encoding)
    workDate && formData.append('workDate', workDate)
    dispatch(csvImportTargets(workspaceId, formData))
    setSubmitted(true)
  }, [characterCode.value, dispatch, file, workDate, workspaceId])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('作業目標のインポートに失敗しました')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  React.useEffect(() => {
    if (!isOpen) {
      return
    }
    setFile(null)
    setCharacterCode(characterCodeItem[0])
    setModalErrorMessage(undefined)
  }, [isOpen])

  const handleCancelClick = React.useCallback(() => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }, [setModalErrorMessage, setSubmitted, onCancel])

  return (
    <CustomModal
      isOpen={isOpen}
      title={`作業目標インポート ${workDate ? `: ${formatDate(workDate)}` : ''}`}
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleApprove}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="targets-import-submit"
    >
      <div>
        <div className="mb-3">
          {`下記${FORMAT_FILE_NAME}をダウンロードして、同じフォーマットでCSVアップロードをしてください。`}
        </div>
        <SelectBoxFormat
          label="文字コード選択"
          value={characterCode.key}
          size={ColumnSizes.middle}
          items={characterCodeItem}
          onChange={item => setCharacterCode(item as CharacterCodeItemType)}
          className="w-60 mb-3"
        />
        <ul className="mb-3">
          <li>
            目標はワークスペース毎に一括入力が可能です。ヘッダーの作業名が登録されている作業名と一致している必要があります。
          </li>
          <li>空白の場合は操作しません。作業目標を削除したい場合は0を入力してアップロードしてください。</li>
          {workDate && <li>該当日の目標のみ取り込みます｡</li>}
        </ul>
        <Button
          color="link"
          className="mb-3 ps-0 shadow-none text-decoration-none"
          onClick={() => {
            handleDataFormat()
            onCancel()
            dispatch(showSuccess())
          }}
        >
          {`${FORMAT_FILE_NAME}をダウンロード`}
        </Button>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default ImportTargetsDialog
